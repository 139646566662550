/**
 * Determine the mobile operating system.
 * This function returns one of 'iOS', 'Android', 'Windows Phone', or 'unknown'.
 *
 * @returns {String}
 */
export function getMobileOperatingSystem() {
    var userAgent = navigator.userAgent || window.opera;

    if (/android/i.test(userAgent)) {
        return "Android";
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return "iOS";
    }

    return "unknown";
}

export function getDownloadLink() {
    const device = getMobileOperatingSystem();
    if (device === "iOS") {
        return 'https://apps.apple.com/ca/app/statrat-baseball/id506977885';
    } else if (device === "Android") {
        return 'https://play.google.com/store/apps/details?id=com.statrat.statratandroid&hl=en_CA&pli=1';
    }
    return 'https://www.statrat.ca';
};
