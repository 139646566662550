import { useEffect, useState } from 'react';
import { Box, Divider, Typography } from '@mui/material';
import { DashedHeader } from '../Shared';
import { formatDate } from '../../helpers/formatDate';
import { trainingTypeStrings } from '../../constants';
import useScreenSize from '../../helpers/useScreenSize';

const TrainingInfo = ({ gameData }) => {
    const screenSize = useScreenSize();
    const variant = screenSize.width < 1200 ? 'h7' : 'h6';

    const [trainingTypeString, setTrainingTypeString] = useState('');
    const [date, setDate] = useState('');
    const [description, setDescription] = useState('');
    const [trainingStats, setTrainingStats] = useState({});

    useEffect(() => {
        setTrainingTypeString(trainingTypeStrings[gameData.trainingType]);
        setDate(formatDate(gameData.date));
        setDescription(gameData.gameDesc);
        setTrainingStats(gameData.trainingStats);
    }, [gameData]);

    const trainingDataFields = [
        { value: date, label: "DATE" },
        { value: trainingTypeString, label: "TRAINING TYPE" }
    ];

    return (
        <>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <DashedHeader title='TRAINING' pt={1} pb={1} />
                <Box pt={1} sx={{ display: 'flex', flexDirection: 'column', gap: 1, background: 'white' }}>
                    {trainingDataFields.map((item) => {
                        return <div key={item.label}>
                            <Box pl={2} pr={2} sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Typography color='black' variant={variant}>{item.label}</Typography>
                                <Typography color='black' variant={variant}>{item.value}</Typography>
                            </Box>
                            <Divider sx={{ backgroundColor: 'lightgrey' }} />
                        </div>
                    })}
                    {description !== "" &&
                        <Box pl={2} pr={2} sx={{ display: 'flex', flexDirection: 'column' }}>
                            <Typography color='black' variant={variant}>DESCRIPTION</Typography>
                            <Typography color='black' variant={variant}>{description}</Typography>
                        </Box>
                    }
                </Box>
                <DashedHeader title='STATS' pt={1} pb={1} />
                <Box pt={1} sx={{ display: 'flex', flexDirection: 'column', gap: 1, background: 'white' }}>
                    {Object.entries(trainingStats).map(([key, value]) => {
                        return <div key={key}>
                            <Box pl={2} pr={2} sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Typography color='black' variant={variant}>{key.toUpperCase()}</Typography>
                                <Typography color='black' variant={variant}>{value}</Typography>
                            </Box>
                            <Divider sx={{ backgroundColor: 'lightgrey' }} />
                        </div>
                    })}
                </Box>
            </Box>
        </>
    )
}

export default TrainingInfo;
