import { Box, Typography } from "@mui/material";
import VideocamIcon from '@mui/icons-material/Videocam';
import useScreenSize from '../../../helpers/useScreenSize';

const Media = ({ thumbs, urls, toggleMediaView }) => {
    const screenSize = useScreenSize();
    const mediaHeight = screenSize.width < 1200 ? 100 : 120;

    function isVideo(index) {
        return !urls[index].endsWith('jpg');
    }

    function onMediaClick(e, url) {
        e.stopPropagation();
        toggleMediaView(url);
    }

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', alignItems: 'center', gap: 2 }}>
            {screenSize.width >= 1200 &&
                <Typography variant="h6">GAME MEDIA</Typography>
            }
            <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%', gap: 2 }}>
                {thumbs.map((thumb, index) => {
                    return <div
                        onClick={(e) => { onMediaClick(e, urls[index]) }}
                        key={thumb}
                        style={{
                            height: mediaHeight,
                            width: '100%',
                            position: 'relative',
                            overflow: 'hidden',
                            aspectRatio: 3 / 2,
                            cursor: 'pointer'
                        }}
                    >
                        {isVideo(index) &&
                            <VideocamIcon color="primary" sx={{ position: 'absolute', top: 0, right: 0, margin: 0.3 }} />
                        }
                        <img
                            src={thumb}
                            alt={`User Uploaded Media ${index}`}
                            loading="lazy"
                            style={{
                                height: mediaHeight,
                                width: '100%',
                                overflow: 'hidden',
                                objectFit: 'cover',
                                aspectRatio: 3 / 2,
                                borderRadius: '10px',
                                pointerEvents: 'none'
                            }}
                        />
                    </div>
                })}
            </Box>
        </Box>
    )
}

export default Media;
