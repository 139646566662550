import { Box } from '@mui/material';
import { DashedHeader, StraightDivider } from '../Shared';
import useScreenSize from '../../helpers/useScreenSize';

import ProfileImage from './ProfileImage';
import GameStats from './GameStats';
import TrainingStats from './TrainingStats';
import Media from './Media';
import ActionBar from './ActionBar';

const GameCard = ({ gameData, toggleMediaView }) => {
    const screenSize = useScreenSize();
    const dividerHeight = screenSize.width < 1200 ? 130 : 200;

    const name = gameData.name.toUpperCase();
    const username = gameData.username.toUpperCase();
    const isTraining = gameData.trainingType > 0;

    return (
        <>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, paddingTop: 1 }}>
                <DashedHeader title={`${name} - ${username}`} />
                <Box pl={2} pr={2} sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                    <ProfileImage imageUrl={gameData.imageUrl} />
                    <StraightDivider height={dividerHeight} />
                    {isTraining ? (
                        <TrainingStats gameData={gameData} />
                    ) : (
                        <GameStats gameData={gameData} />
                    )}
                    {screenSize.width >= 1200 && gameData.media.media.length > 0 &&
                        <>
                            <StraightDivider height={dividerHeight} />
                            <Media
                                thumbs={gameData.media.thumbs}
                                urls={gameData.media.urls}
                                toggleMediaView={toggleMediaView}
                            />
                        </>
                    }
                </Box>
                <ActionBar commentCount={gameData.commentCount} />
                {screenSize.width < 1200 && gameData.media.media.length > 0 &&
                    <Box p={1} pl={2} pr={2}>
                        <Media
                            thumbs={gameData.media.thumbs}
                            urls={gameData.media.urls}
                            toggleMediaView={toggleMediaView}
                        />
                    </Box>
                }
            </Box>
        </>
    )
}

export default GameCard;
