import { Badge, Box, Typography } from "@mui/material";
import { statRatBlackSecondary, statRatBlackTertiary } from "../../../constants";
import useScreenSize from "../../../helpers/useScreenSize";

import likeIcon from "./likeIcon.png";
import commentIcon from "./commentIcon.png";
import shareIcon from "./shareIcon.png";

const ActionButton = ({ icon, text, badgeCount }) => {
    const screenSize = useScreenSize();
    const iconWidth = 29 / (screenSize.width < 1200 ? 1.3 : 1);
    const iconHeight = 23.5 / (screenSize.width < 1200 ? 1.3 : 1);
    const textVariant = screenSize.width < 1200 ? 'body2' : 'body1';

    return (
        <Badge badgeContent={badgeCount} color="primary">
            <Box sx={{
                display: 'flex',
                gap: 1,
                border: 'solid',
                borderWidth: '2px',
                borderColor: statRatBlackTertiary,
                p: 0.8
            }}>
                <img src={icon} alt={text} style={{ width: iconWidth, height: iconHeight }} />
                {text && <Typography variant={textVariant} color={statRatBlackTertiary}>{text}</Typography>}
            </Box>
        </Badge>
    )
}

const ActionBar = ({ commentCount }) => {
    return (
        <Box p={0.8} sx={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            background: statRatBlackSecondary,
            justifyContent: 'space-between'
        }}>
            <Box p={1} pl={1.2} sx={{ display: 'flex', gap: 2 }}>
                <ActionButton icon={likeIcon} text="LIKE" />
                <ActionButton icon={commentIcon} text="COMMENT" badgeCount={commentCount} />
            </Box>
            <Box p={1} pr={1.2}>
                <ActionButton icon={shareIcon} />
            </Box>
        </Box>
    )
}

export default ActionBar;
