import { Avatar } from "@mui/material";
import useScreenSize from "../../../helpers/useScreenSize";
import placeholderIcon from './placeholder.png';

const ProfileImage = ({ imageUrl }) => {
    const screenSize = useScreenSize();
    const profileImageSize = screenSize.width < 1200 ? 100 : 150;

    return (
        <Avatar
            alt="Avatar"
            src={imageUrl}
            sx={{ width: profileImageSize, height: profileImageSize, pointerEvents: 'none' }}
        >
            <img 
                alt="Avatar"
                src={placeholderIcon}
                style={{ width: profileImageSize, height: profileImageSize }}
            />
        </Avatar>
    )
}

export default ProfileImage;
