export const statRatRed = '#E20033';
export const statRatBlackPrimary = '#2B2B2B';
export const statRatBlackSecondary = '#1B1B1B';
export const statRatBlackTertiary = '#9A9A9A';

export const statRatGreen = '#008000';
export const statRatOrange = '#FFA500';

export const trainingTypeStrings = {
    0: '',
    1: 'Batting',
    2: 'Pitching',
    3: 'Fielding',
    4: 'Base Running',
    5: 'General Training'
}

const today = new Date();

export const emptyGameData = {
    batting: [
        { 'H': 0 }, { 'AB': 0 }, { 'RBI': 0 }, { 'K': 0 }, { 'BB': 0 }
    ],
    battingScore: [
        { 'AB': 0 },
        { 'H': 0 },
        { '2B': 0 },
        { '3B': 0 },
        { 'HR': 0 },
        { 'RBI': 0 },
        { 'BB': 0 },
        { 'K': 0 },
        { 'HBP': 0 },
        { 'SB': 0 },
        { 'SBA': 0 },
        { 'SH': 0 },
        { 'SF': 0 },
        { 'GIDP': 0 },
        { 'FC': 0 }
    ],
    catchup: 0,
    commentCount: 0,
    date: `${today.getFullYear}-${today.getMonth}-${today.getDay}`,
    feedDisplay: 0,
    gameDesc: "",
    id: 0,
    imageUrl: "",
    liked: false,
    likesCount: 0,
    location: "",
    media: { media: [], thumbs: [], urls: [] },
    name: "",
    opponent: "",
    pitching: [
        { 'IP': 0 }, { 'H': 0 }, { 'K': 0 }, { 'BB': 0 }, { 'ERA': 0 }
    ],
    pitchingScore: [
        { 'IP': 0 },
        { 'H': 0 },
        { 'R': 0 },
        { 'ER': 0 },
        { 'BB': 0 },
        { 'HB': 0 },
        { 'WP': 0 },
        { 'BK': 0 },
        { 'K': 0 },
        { 'SO': 0 },
        { 'W': 0 },
        { 'L': 0 },
        { 'SV': 0 },
        { 'BS': 0 },
        { 'GS': 0 },
        { 'CG': 0 }
    ],
    playerId: 0,
    scoreOpp: 0,
    scoreYou: 0,
    trainingStats: null,
    trainingType: 0,
    username: "",
    uuid: ""
};
