import { useEffect, useState } from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { Box, CssBaseline, Toolbar } from '@mui/material';
import Navbar from './components/Navbar';
import DownloadOverlay from './components/DownloadOverlay';
import MediaView from './components/MediaView';
import GameCard from './components/GameCard';
import GameInfo from './components/GameInfo';
import TrainingInfo from './components/TrainingInfo';
import { emptyGameData, statRatBlackPrimary, statRatRed } from './constants';

const theme = createTheme({
    palette: {
        mode: "dark",
        primary: { main: statRatRed },
        background: { default: statRatBlackPrimary },
    },
    typography: {
        fontFamily: 'Inter',
        fontWeight: '600',
        h7: {
            fontSize: 18
        }
    }
});

const hostname = window.location.hostname;
const server = hostname === 'game.statratapp.com' ? 'appb.statratapp.com' : 'devb.statratapp.com';

function App() {
    const [showDownloadView, setShowDownloadView] = useState(false);
    const toggleDownloadView = () => {
        setShowDownloadView(!showDownloadView);
    };
    const [disableHide, setDisableHide] = useState(false);
    function toggleInvalidUidScreen() {
        setDisableHide(true);
        setShowDownloadView(true);
    }

    const [mediaUrl, setMediaUrl] = useState('');
    const [showMediaView, setShowMediaView] = useState(false);
    function toggleMediaView(url) {
        setMediaUrl(url);
        setShowMediaView(!showMediaView);
    };

    const [gameData, setGameData] = useState(emptyGameData);
    const isTraining = gameData.trainingType > 0;

    useEffect(() => {
        const pathRegex = /^\/game\/([^/]*)\/?$/;
        const pathname = window.location.pathname;
        const matches = pathname.match(pathRegex);
        const uid = matches !== null ? matches[1] : '';

        if (uid !== '') {
            fetch(`https://${server}/v1/share/game/${uid}`)
                .then(response => response.json())
                .then(data => {
                    if (data['c'] === 1 && data['v'] !== null) {
                        setGameData(data['v']);
                    } else {
                        toggleInvalidUidScreen();
                    }
                })
                .catch(_error => {
                    toggleInvalidUidScreen();
                });
        } else {
            toggleInvalidUidScreen();
        }
    }, []);

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <DownloadOverlay
                visible={showDownloadView}
                toggleDownloadView={toggleDownloadView}
                name={gameData.name}
                disable={disableHide}
            />
            <MediaView visible={showMediaView} toggleMediaView={toggleMediaView} url={mediaUrl} />
            {!disableHide &&
                <Box sx={{ display: 'flex' }}>
                    <Navbar />
                    <Box width={'100%'} component="main" onClick={toggleDownloadView}>
                        <Toolbar />
                        <GameCard gameData={gameData} toggleMediaView={toggleMediaView} />
                        {isTraining ? (
                            <TrainingInfo gameData={gameData} />
                        ) : (
                            <GameInfo gameData={gameData} />
                        )}
                    </Box>
                </Box>
            }
        </ThemeProvider>
    );
}

export default App;
