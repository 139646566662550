import { AppBar, Button, Link, Toolbar, Typography } from '@mui/material';
import useScreenSize from '../../helpers/useScreenSize';
import { getDownloadLink } from '../../helpers/getMobileOperatingSystem';
import logo from '../../logo.png';

const Navbar = () => {
    const screenSize = useScreenSize();
    const buttonVariant = screenSize.width < 1200 ? 'caption' : 'body1';
    const buttonText = screenSize.width < 1200 ? 'Download' : 'Download Statrat';

    return (
        <AppBar component="nav" position='fixed'>
            <Toolbar display='flex' sx={{ justifyContent: 'space-between' }}>
                <Button variant='contained' sx={{ visibility: 'hidden' }}>
                    <Typography variant={buttonVariant}>{buttonText}</Typography>
                </Button>
                <Link href="https://www.statrat.ca" display='flex'>
                    <img src={logo} alt="StatRat" style={{ width: 97.5, height: 40.25 }} />
                </Link>
                <Button variant='contained'>
                    <Link href={getDownloadLink()} style={{ textDecoration: 'none', color: 'white' }}>
                        <Typography variant={buttonVariant}>{buttonText}</Typography>
                    </Link>
                </Button>
            </Toolbar>
        </AppBar>
    )
}

export default Navbar
