import React, { useEffect, useState } from "react";
import { Avatar, Box, Typography } from "@mui/material";
import { StraightDivider } from "../../Shared";
import useScreenSize from "../../../helpers/useScreenSize";
import { formatDate } from "../../../helpers/formatDate";
import * as Color from '../../../constants';

const GameStats = ({ gameData }) => {
    const screenSize = useScreenSize();

    const [result, setResult] = useState('');
    const [resultColor, setResultColor] = useState(Color.statRatBlackSecondary);
    const resultSize = screenSize.width < 1200 ? 50 : 80;
    const resultTextSize = screenSize.width < 1200 ? 30 : 40;
    const teamNameVariant = screenSize.width < 1200 ? 'h7' : 'h5';

    const [date, setDate] = useState('');
    const [stats, setStats] = useState([]);
    const [lastStat, setLastStat] = useState('');

    useEffect(() => {
        if (gameData.catchup === 1) {
            setResult('C');
            setResultColor(Color.statRatOrange);
        } else if (gameData.scoreYou > gameData.scoreOpp) {
            setResult('W');
            setResultColor(Color.statRatGreen);
        } else if (gameData.scoreYou < gameData.scoreOpp) {
            setResult('L');
            setResultColor(Color.statRatRed);
        } else {
            setResult('T');
            setResultColor(Color.statRatOrange);
        }

        setDate(formatDate(gameData.date));

        if (gameData.feedDisplay === 0) {
            setStats(gameData.batting);
            setLastStat('BB');
        } else {
            setStats(gameData.pitching);
            setLastStat('ERA');
        }

    }, [gameData]);

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', gap: 2, justifyContent: 'center' }}>
            <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2, alignItems: 'center' }}>
                <Avatar alt="Game Result" sx={{
                    bgcolor: Color.statRatBlackPrimary,
                    boxShadow: `0px 0px 0px 1px ${Color.statRatBlackSecondary}`,
                    width: resultSize,
                    height: resultSize
                }}>
                    <Typography color={resultColor} fontSize={resultTextSize}>{result}</Typography>
                </Avatar>
                <Box display={'flex'} flexDirection='column'>
                    <Typography variant={teamNameVariant}>vs. {gameData.opponent}</Typography>
                    <Typography variant='body2' color={Color.statRatBlackTertiary}>{date}</Typography>
                </Box>
            </Box>
            <StraightDivider />
            <Box sx={{
                display: 'flex',
                flexDirection: 'row',
                width: '100%',
                justifyContent: 'space-evenly'
            }}>
                {stats.map((stat) => {
                    return Object.entries(stat).map(([key, value]) => {
                        return <React.Fragment key={key}>
                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                <Typography color={Color.statRatBlackTertiary}>{key}</Typography>
                                <Typography>{value}</Typography>
                            </Box>
                            {key !== lastStat && <StraightDivider />}
                        </React.Fragment>
                    })
                })}
            </Box>
        </Box>
    )
}

export default GameStats;
