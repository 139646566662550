import { useEffect, useState } from 'react';
import { Box, Divider, Typography } from '@mui/material';
import { DashedHeader } from '../Shared';
import { formatDate } from '../../helpers/formatDate';
import useScreenSize from '../../helpers/useScreenSize';

const GameInfo = ({ gameData }) => {
    const screenSize = useScreenSize();
    const variant = screenSize.width < 1200 ? 'h7' : 'h6';

    const [opponent, setOpponent] = useState('');
    const [location, setLocation] = useState('');
    const [scoreYou, setScoreYou] = useState('');
    const [scoreOpp, setScoreOpp] = useState('');
    const [date, setDate] = useState('');
    const [description, setDescription] = useState('');
    const [battingScore, setBattingScore] = useState([]);
    const [pitchingScore, setPitchingScore] = useState([]);

    useEffect(() => {
        const boolKeys = ["SO", "W", "L", "SV", "BS", "GS", "CG"];
        const floatKeys = ["IP"];

        setOpponent(gameData.opponent);
        setLocation(gameData.location);
        setScoreYou(gameData.scoreYou);
        setScoreOpp(gameData.scoreOpp);
        setDate(formatDate(gameData.date));
        setDescription(gameData.gameDesc);
        setBattingScore(gameData.battingScore.map((score) => {
            const key = Object.keys(score)[0];
            if (boolKeys.includes(key)) {
                score[[key]] = score[[key]] === 1 ? "YES" : "NO"
            } else if (floatKeys.includes(key)) {
                score[[key]] = (score[[key]] / 100).toFixed(2);
            }
            return score;
        }));
        setPitchingScore(gameData.pitchingScore.map((score) => {
            const key = Object.keys(score)[0];
            if (boolKeys.includes(key)) {
                score[[key]] = score[[key]] === 1 ? "YES" : "NO"
            } else if (floatKeys.includes(key)) {
                score[[key]] = (score[[key]] / 100).toFixed(2);
            }
            return score;
        }));
    }, [gameData]);

    const gameDataFields = [
        { value: opponent, label: "VS" },
        { value: location, label: "LOCATION" },
        { value: scoreYou, label: "YOUR SCORE" },
        { value: scoreOpp, label: "VS SCORE" },
        { value: date, label: "DATE" }
    ];

    const battingDataLabels = {
        "AB": "AT BATS",
        "H": "TOTAL HITS",
        "2B": "DOUBLE",
        "3B": "TRIPLE",
        "HR": "HOME RUNS",
        "RBI": "RUNS BATTED IN",
        "BB": "WALKS",
        "K": "STRIKE OUT",
        "HBP": "HIT BY PITCH",
        "SB": "STOLEN BASES",
        "SBA": "STOLEN BASE ATTEMPTS",
        "SH": "SAC BUNT",
        "SF": "SAC FLY",
        "GIDP": "DOUBLE PLAYS INDUCED",
        "FC": "FIELDER'S CHOICE"
    };

    const pitchingDataLabels = {
        "IP": "INNINGS PITCHED",
        "H": "HITS",
        "R": "RUNS",
        "ER": "EARNED RUNS",
        "BB": "WALKS",
        "HB": "HIT BATTERS",
        "WP": "WILD PITCHES",
        "BK": "BALKS",
        "K": "STRIKE OUT",
        "SO": "SHUTOUT",
        "W": "WIN",
        "L": "LOSE",
        "SV": "SAVE",
        "BS": "BLOWN SAVE",
        "GS": "GAME STARTED",
        "CG": "COMPLETE GAME",
        "ERA": "Earned Run Average"
    };

    return (
        <>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <DashedHeader title='GAME INFO' pt={1} pb={1} />
                <Box pt={1} sx={{ display: 'flex', flexDirection: 'column', gap: 1, background: 'white' }}>
                    {gameDataFields.map((item) => {
                        return <div key={item.label}>
                            <Box pl={2} pr={2} sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Typography color='black' variant={variant}>{item.label}</Typography>
                                <Typography color='black' variant={variant}>{item.value}</Typography>
                            </Box>
                            <Divider sx={{ backgroundColor: 'lightgrey' }} />
                        </div>
                    })}
                    {description !== "" &&
                        <Box pl={2} pr={2} sx={{ display: 'flex', flexDirection: 'column' }}>
                            <Typography color='black' variant={variant}>DESCRIPTION</Typography>
                            <Typography color='black' variant={variant}>{description}</Typography>
                        </Box>
                    }
                </Box>
                <DashedHeader title='BATTING' pt={1} pb={1} />
                <Box pt={1} sx={{ display: 'flex', flexDirection: 'column', gap: 1, background: 'white' }}>
                    {battingScore.map((score) => {
                        return Object.entries(score).map(([key, value]) => {
                            return <div key={battingDataLabels[key]}>
                                <Box pl={2} pr={2} sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <Typography color='black' variant={variant}>{battingDataLabels[key]}</Typography>
                                    <Typography color='black' variant={variant}>{value}</Typography>
                                </Box>
                                <Divider sx={{ backgroundColor: 'lightgrey' }} />
                            </div>
                        })
                    })}
                </Box>
                <DashedHeader title='PITCHING' pt={1} pb={1} />
                <Box pt={1} sx={{ display: 'flex', flexDirection: 'column', gap: 1, background: 'white' }}>
                    {pitchingScore.map((score) => {
                        return Object.entries(score).map(([key, value]) => {
                            return <div key={pitchingDataLabels[key]}>
                                <Box pl={2} pr={2} sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <Typography color='black' variant={variant}>{pitchingDataLabels[key]}</Typography>
                                    <Typography color='black' variant={variant}>{value}</Typography>
                                </Box>
                                <Divider sx={{ backgroundColor: 'lightgrey' }} />
                            </div>
                        })
                    })}
                </Box>
            </Box>
        </>
    )
}

export default GameInfo;
