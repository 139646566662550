import { Typography } from '@mui/material';
import { statRatRed, statRatBlackSecondary } from '../../constants';
import useScreenSize from '../../helpers/useScreenSize';

export const DashedDivider = () => {
    return (
        <div style={{ borderRadius: 1, borderStyle: 'dashed', borderWidth: 1, borderColor: statRatRed }} />
    )
}

export const StraightDivider = ({ height }) => {
    return (
        <div style={{
            borderRadius: 1,
            borderStyle: 'solid',
            borderWidth: 1,
            height: height,
            borderColor: statRatBlackSecondary
        }} />
    )
}

export const DashedHeader = ({ title, ...props }) => {
    const screenSize = useScreenSize();
    const variant = screenSize.width < 1200 ? 'h7' : 'h6';

    return (
        <>
            <DashedDivider />
            <Typography pl={2} variant={variant} {...props}>{title}</Typography>
            <DashedDivider />
        </>
    )
}
