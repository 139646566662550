import { useEffect, useState } from 'react';
import ReactHlsPlayer from 'react-hls-player';
import { isSafari } from 'react-device-detect';
import { Box, CircularProgress } from '@mui/material';

const MediaView = ({ visible, toggleMediaView, url }) => {
    const [mediaType, setMediaType] = useState(null);

    useEffect(() => {
        if (url !== '') {
            if (url.endsWith('jpg')) { setMediaType('image'); }
            else { setMediaType('video'); }
        } else {
            setMediaType(null);
        }
    }, [url]);

    return (
        <Box
            onClick={() => { toggleMediaView('') }}
            p={5}
            sx={{
                backgroundColor: `rgba(0, 0, 0, 0.8)`,
                display: 'flex',
                width: '100%',
                height: '100%',
                position: 'fixed',
                justifyContent: 'center',
                alignItems: 'center',
                gap: 5,
                transition: 'all .2s',
                opacity: !visible ? '0' : '1',
                visibility: !visible ? 'hidden' : 'visible',
                zIndex: 1500
            }}
        >
            {mediaType === 'image' ? (
                <img
                    src={url}
                    alt={'User Uploaded Media'}
                    loading='lazy'
                    style={{ height: '100%', width: '100%', objectFit: 'contain', pointerEvents: 'none' }}
                />
            ) : mediaType === 'video' && isSafari ? (
                <video width='100%' height='100%' autoPlay loop playsInline>
                    <source src={url} type="application/vnd.apple.mpegurl" />
                </video>
            ) : mediaType === 'video' ? (
                <ReactHlsPlayer src={url} width='100%' height='100%' autoPlay loop />
            ) : (
                <CircularProgress />
            )}
        </Box>
    )
}

export default MediaView;
