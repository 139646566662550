import { Box, Button, Link, Typography } from '@mui/material';
import logo from '../../logo.png';
import { statRatRed } from '../../constants';
import { getDownloadLink } from '../../helpers/getMobileOperatingSystem';
import useScreenSize from '../../helpers/useScreenSize';

const DownloadOverlay = ({ name, visible, toggleDownloadView, disable }) => {
    const screenSize = useScreenSize();
    const logoWidth =  390 / (screenSize.width < 1200 ? 1.5 : 1);
    const logoHeight = 161 / (screenSize.width < 1200 ? 1.5 : 1);
    const textVariant = screenSize.width < 1200 ? 'h7' : 'h6';
    const gap = screenSize.width < 1200 ? 4 : 5;

    return (
        <Box
            onClick={disable ? null : toggleDownloadView}
            p={5}
            sx={{
                backgroundColor: `rgba(0, 0, 0, 0.8)`,
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                height: '100%',
                position: 'fixed',
                justifyContent: 'center',
                alignItems: 'center',
                gap: gap,
                transition: 'all .2s',
                opacity: !visible ? "0" : "1",
                visibility: !visible ? 'hidden' : 'visible',
                zIndex: 1500
            }}
        >
            <img src={logo} alt="StatRat" style={{ width: logoWidth, height: logoHeight }} />
            <Typography variant={textVariant} align='center'>
                Statrat is a free social stat tracking platform for ball players.
            </Typography>
            <Typography variant={textVariant} align='center'>
                {<span style={{ color: statRatRed }}>Download the app on your phone</span>}
                {name !== "" ? ` to follow ${name} and` : ""} to track your own stats.
            </Typography>
            <Button variant='contained'>
                <Link href={getDownloadLink()} style={{ textDecoration: 'none', color: 'white' }}>
                    <Typography p={1} variant={textVariant} align="center">Download the App</Typography>
                </Link>
            </Button>
            <Button variant='contained'>
                <Link href="https://www.statrat.ca" style={{ textDecoration: 'none', color: 'white' }}>
                    <Typography p={1} variant={textVariant} align="center">Learn More About Statrat</Typography>
                </Link>
            </Button>
        </Box>
    )
}

export default DownloadOverlay;
