import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { formatDate } from "../../../helpers/formatDate";
import { statRatBlackTertiary, statRatRed, trainingTypeStrings } from '../../../constants';

const TrainingStats = ({ gameData }) => {
    const [date, setDate] = useState('');
    const [trainingTypeString, setTrainingTypeString] = useState("");

    useEffect(() => {
        setDate(formatDate(gameData.date));
        setTrainingTypeString(trainingTypeStrings[gameData.trainingType]);
    }, [gameData]);

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', gap: 2, justifyContent: 'center' }}>
            <Box display={'flex'} flexDirection='column'>
                <Typography variant={'h5'}>Training Session</Typography>
                <Typography variant={'h6'}>
                    <span style={{ color: statRatRed }}>TYPE:</span> {trainingTypeString}
                </Typography>
                <Typography variant='body2' color={statRatBlackTertiary}>{date}</Typography>
            </Box>
        </Box>
    )
}

export default TrainingStats;
